/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


// import Rails from "@rails/ujs"
// Rails.start()
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
import "core-js/stable"
import "regenerator-runtime/runtime"

import I18n from 'i18n-js/index.js.erb'
window._ = require('lodash');
// import $ from 'jquery';
require("bootstrap");
import 'bootstrap/js/dist/modal';
require('datatables.net-bs4')

window.is = require("is_js")
window.bodymovin = require("bodymovin")

import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all";


import submit_phone_number from 'src/clients/new'

// $(document).on("turbolinks:load", function() {
//   gtag('config', 'UA-146778271-2', {'page_location': event.data.url});
// })

// import {analytics} from 'src/ga.js'

// analytics.page()

import gtag, { install } from 'ga-gtag';
install('G-VZZCC8MLD1');
