import $ from 'jquery';
const submit_phone_number = () => {

  $("#phone_number").css("border-color","#b1bbc6");
  $("#personal_email").css("border-color","#b1bbc6");


    var phone_number = parseInt(document.getElementById("phone_number").value)
    var personal_email = document.getElementById("personal_email").value
    var phone_patt = new RegExp("^\\d{8}$", "g");
    var email_patt = new RegExp("^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$");

    var isError = false

    if (phone_number.length == 0 || !phone_patt.test(phone_number)){
        // TODO: Show error msg

      isError = true

      $("#phone_number").css("border-color","#ff0000");
    }

    if (personal_email.length == 0 || !email_patt.test(personal_email)) {
        // TODO: Show error msg

      isError = true

      $("#personal_email").css("border-color","#ff0000");
    }

    if ( !document.getElementById('t_and_c').checked ){
          // TODO: Show error msg
        isError = true
        $("#t_and_c").css("border-color","#ff0000");
        // return
    }

    if ( !document.getElementById('must_know').checked ){
          // TODO: Show error msg
         isError = true
         $("#must_know").css("border-color","#ff0000");
        // return
    }

    if(!isError){
      $.ajax({
        type: "post",
        url: window.location.origin + "/auth",
        dataType:"json",
        data: {"phone_number": phone_number, "personal_email": personal_email},
        success: function (response) {
            if(response.status === "success") {
                // do something with response.message or whatever other data on success
                window.location.href = '/clients/verification?phone_number=' + phone_number + '&personal_email=' + personal_email 
                  + '&verification_sent_time=' + Date.now();
            } else if(response.status === "error") {
              console.log("error")
                // do something with response.message or whatever other data on error
            }
        },
        error: function(XMLHttpRequest, textStatus, errorThrown) { 
        }
                   
      });
    }
}

export {submit_phone_number}